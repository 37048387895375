import { useEffect } from "react";
import CategoryList from "../../../components/AdminComponents/category/CategoryList.jsx";
import { usePageTitle } from "../../../context/PageTitle.jsx";

const Category = () => {
  const { setPageTitle } = usePageTitle();
  useEffect(() => setPageTitle("Categories"), []);
  return (
    <>
      <div className="flex h-screen">
        <CategoryList />
      </div>
    </>
  );
};

export default Category;
