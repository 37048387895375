import React from "react";
import { Trash2 } from "lucide-react";
import { Button, Img } from "../../../components/UserComponents";
const WishlistItem = ({
  _id,
  thumbnail,
  name,
  price,
  actualPrice,
  discount,
  offer,
  handleAddtoCart,
  handleDeleteFromWishlist,
}) => {
  return (
    <div
      key={_id}
      className=" bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:shadow-lg hover:-translate-y-1"
    >
      <div className="relative h-64">
        <img
          src={thumbnail}
          alt={name}
          className="w-full h-64 object-contain lg:object-cover"
        />
        <button
          onClick={handleDeleteFromWishlist}
          className="absolute top-2 right-2 bg-white p-1 rounded-full shadow-md hover:bg-red-50"
        >
          <Trash2 className="h-5 w-5 text-red-500" />
        </button>
        {offer && (
          <span className="absolute bottom-2 left-2 bg-green-500 text-white px-2 py-1 rounded-md text-sm font-medium">
            {discount || 0} % off
          </span>
        )}
      </div>
      <div className="p-4">
        <h3 className="font-semibold text-md mb-2">{name}</h3>
        <div className="flex items-center mb-2">
          <span className="text-lg font-bold text-blue-600 mr-2">${price}</span>
          {price !== actualPrice && (
            <span className="text-sm text-gray-500 line-through">
              ${actualPrice}
            </span>
          )}
        </div>

        <div className="flex justify-center items-center">
          <Button
            color="gray_900"
            size="sm"
            leftIcon={
              <Img
                src="/images/cart.svg"
                alt="svg"
                className="h-[16px] w-[16px]"
              />
            }
            onClick={handleAddtoCart}
            className="min-w-full gap-2 rounded-[26px] px-5 font-medium sm:px-5 disabled:opacity-50"
          >
            Move to cart
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WishlistItem;
