import React, { useCallback, useEffect, useRef, useState } from "react";
import { Heading, Img } from "./..";
import QuantityButton from "../QuantityButton";
import { useDispatch } from "react-redux";
import {
  removeFromCart,
  updateQuantity,
} from "../../../redux/slices/CartSlice";
import axiosInstance from "../../../utils/api/axiosInstance";
import { USER_URL } from "../../../constants";
import showToast from "../../../utils/showToast";
import { Trash } from "lucide-react";
import { removeItemFromCart } from "../../../utils/api/cart";
import { Link } from "react-router-dom";

export default function CheckoutProduct({
  _id,
  name,
  stock,
  quantity,
  price,
  thumbnail,
  handleRemoveFromCart,
  isDisabled = false,
}) {
  const dispatch = useDispatch();
  const handleQuantityChange = useCallback((quantity, action) => {
    let newQuantity = quantity; // Quantity from input field
    if (action === "INCREMENT") {
      newQuantity += 1;
    } else {
      newQuantity -= 1;
    }
    // If new quantity is 0 or less, remove the item from cart
    if (newQuantity <= 0) {
      removeItemFromCart(_id)
        .then(() => {
          showToast("Product removed from cart successfully");
          dispatch(removeFromCart({ _id }));
        })
        .catch((e) => showToast(e.message, "error"));
    } else {
      if (newQuantity > stock) {
        showToast(
          "We apologize, but there is not enough stock available.",
          "dark"
        );
        return;
      }
      if (newQuantity > 5) {
        showToast(
          "Oops! You've reached the maximum limit of 5 units for this product.",
          "dark"
        );
        return;
      }

      axiosInstance
        .patch(USER_URL + `/cart`, { productId: _id, count: newQuantity })
        .then(() => {
          dispatch(updateQuantity({ _id, quantity: newQuantity }));
          showToast("Quantity updated successfully");
        })
        .catch((e) => showToast(e.message, "error"));
    }
  }, []);

  return (
    <div
      className={`flex md:flex-col items-center pt-[23px] gap-6 sm:pt-5 border-gray-200 border-t border-solid`}
    >
      <div className=" rounded-[12px] md:w-full p-3">
        <Link to={`/product/${_id}`}>
          <img
            src={thumbnail}
            alt={`${name} thumbnail`}
            className="h-[108px] w-full rounded-[12px] object-cover md:h-auto"
            key={Date.now()}
          />
        </Link>
      </div>
      <div className="flex flex-1 items-center justify-between md:self-stretch">
        <div className="flex flex-col  items-start gap-[19px] sm:self-stretch px-2">
          <Heading as="h6">{name}</Heading>

          <div className="flex w-[26%] md:w-full">
            <div className="flex w-full justify-evenly gap-4 rounded-[20px] bg-gray-50 ">
              {stock < 1 ? (
                <p className="font-medium text-red-400">out of stock!</p>
              ) : isDisabled ? (
                <p className="inline-flex gap-1">
                  <span>Quantity:</span>
                  {quantity}
                </p>
              ) : (
                <QuantityButton
                  quantity={quantity}
                  handleUpdateQuantity={handleQuantityChange}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center gap-6 sm:gap-3">
          <Heading as="h6" className="text-sm font-normal">
            ${price}
          </Heading>
          <Heading as="h6">${quantity * price}</Heading>
          {!isDisabled && (
            <button
              onClick={() => handleRemoveFromCart(_id)}
              className="text-red-400 bg-red-100 hover:bg-red-200 p-2 text-sm font-medium rounded-md shadow transition duration-100"
            >
              <Trash className="size-5" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
