import React, { useMemo, useRef, useState } from "react";
import { Heading } from "../Heading";
import { Slider } from "../Slider";
import ProductCard from "../ProductCard";
import { Configure, Index, useConfigure, useHits } from "react-instantsearch";
import Skeleton from "../Skeleton";
import useFetchApi from "../../../Hooks/useFetchApi";
import { USER_URL } from "../../../constants";

const TopProducts = () => {
  const [sliderState, setSliderState] = useState(0);
  const sliderRef = useRef(null);
  // useConfigure({
  //   hitsPerPage: 6,
  // });
  // const { items, results } = useHits();
  // const top_5Products = useMemo(() => items, [results]);
  const { data: top_5Products, isLoading } = useFetchApi({
    url: USER_URL + "/products",
    limit: 5,
  });
  return (
    <div className="container-xs mt-10 flex flex-col items-start gap-[29px] md:p-5">
      <Heading size="lg" as="h1" className="!font-dm-sans">
        <span className="text-gray-900">Best Seller.&nbsp;</span>
        <span className="text-blue_gray_700_cc">
          Discover the most popular products
        </span>
      </Heading>

      {isLoading ? (
        <div className="grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-6 mx-2.5">
          {Array.from({ length: 5 }).map((_, i) => (
            <Skeleton key={i} />
          ))}
        </div>
      ) : null}
      {/* <Index indexName="top_selling_products" indexId="top-rated">
        <Configure hitsPerPage={5} filters="isActive:false" /> */}
      <div className="flex flex-col items-center gap-16 self-stretch sm:gap-8">
        <div className="mx-2.5 flex w-full max-w-[1296px] gap-5 self-stretch md:mx-0 md:flex-col">
          <Slider
            autoPlay
            autoPlayInterval={3000}
            responsive={{
              0: { items: 1 },
              550: { items: 2 },
              1050: { items: 4 },
            }}
            renderDotsItem={(props) => {
              return props?.isActive ? (
                <div className="mr-1.5 h-[6px] w-[6px] rounded-[3px] bg-gray_900_cc" />
              ) : (
                <div className="mr-1.5 h-[6px] w-[6px] rounded-[3px] bg-gray_900_66" />
              );
            }}
            activeIndex={sliderState}
            onSlideChanged={(e) => {
              setSliderState(e?.item);
            }}
            ref={sliderRef}
            items={top_5Products.map((item) => (
              <ProductCard
                {...item}
                _id={item.id}
                key={item._id}
                showWishlistIcon={false}
              />
            ))}
          />
        </div>
      </div>
      {/* </Index> */}
    </div>
  );
};

export default TopProducts;
