import React from "react";
import { Img } from "../../../components/UserComponents";

const ProductImages = ({ images, selectedImage, handleSelectImage }) => {
  return (
    <div className="flex gap-4 pb-3 md:flex-col w-full self-start static lg:sticky top-28 md:top-72">
      {/* mobile screens */}
      <div className="flex lg:hidden gap-4 md:w-full  overflow-y-auto scrollbar-hide md:order-2">
        {images?.length ? (
          <>
            {images?.map((image, index) => (
              <Img
                key={"imageList" + index}
                src={image}
                alt="product image"
                onClick={() => handleSelectImage(image)}
                className="size-20 flex-1 rounded-md object-cover hover:cursor-pointer hover:border-2 border-gray-300"
                loading="eager"
              />
            ))}{" "}
          </>
        ) : null}
      </div>
      {/* mobile screens ends */}
      <div className="md:hidden  flex flex-col gap-2 md:w-full md:h-auto h-[450px]  overflow-y-auto scrollbar-hide ">
        {images?.length ? (
          <>
            {images?.map((image, index) => (
              <Img
                key={"imageList" + index}
                src={image}
                alt="product image"
                onClick={() => handleSelectImage(image)}
                onMouseEnter={() => handleSelectImage(image)}
                loading="eager"
                className={`size-20 ${images.length > 3 && "flex-1"}  
                  rounded-md object-cover hover:cursor-pointer hover:border-2 border-gray-300`}
              />
            ))}
          </>
        ) : null}
      </div>
      <div className="sm:order-1 relative lg:h-[450px]  rounded-xl md:h-auto md:w-full md:flex-none border border-gray-200">
        {selectedImage && (
          <Img
            src={selectedImage}
            alt="Main Image"
            loading="eager"
            className="lg:h-[450px] w-full rounded-xl object-contain"
          />
        )}
      </div>
    </div>
  );
};

export default ProductImages;
