import React from "react";
import { useNavigate } from "react-router-dom";

const Banners = ({
  name = " Starting from: $49.99",
  description = `Exclusive collection for everyone`,
  url,
  image = "/images/carousel1.png",
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="aspect-[16/8] lg:aspect-[16/6.5] rounded-md"
      onClick={() => navigate(url)}
    >
      <img
        src={image}
        alt="Hero section"
        className="w-full h-full rounded-md object-cover"
      />
      {/* <div className="container mx-auto flex flex-col md:flex-row items-start md:items-center gap-5">
        <div className="space-y-1 p-2">
          <h1 className="text-white-A700 sm:text-xl text-4xl font-bold">
            {name}
          </h1>

          <Heading
            as="h1"
            className="w-full !font-semibold  brekwo text-white-A700"
          >
            {description}
          </Heading>
          <Link to={url || "/shop?q="}>
            <Button
              color="gray_900"
              size="lg"
              rightIcon={<Search className="size-5 text-white-A700 ml-2" />}
              className="min-w-[198px] gap-px rounded-[32px] font-medium sm:px-5 mt-5"
            >
              Explore now
            </Button>
          </Link>
        </div>
      </div> */}
    </div>

    // <section
    //   className="relative h-[600px]
    //  bg-cover bg-center bg-no-repeat"
    //   style={{ backgroundImage: `url(${image})` }}
    // >
    //   <div className="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l" />
    //   <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
    //     <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
    //       <h1 className="text-3xl font-extrabold sm:text-5xl">
    //         Let us find your
    //         <strong className="block font-extrabold text-rose-700">
    //           {" "}
    //           Forever Home.{" "}
    //         </strong>
    //       </h1>
    //       <p className="mt-4 max-w-lg sm:text-xl/relaxed">
    //         Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt
    //         illo tenetur fuga ducimus numquam ea!
    //       </p>
    //       <div className="mt-8 flex flex-wrap gap-4 text-center">
    //         <a
    //           href="#"
    //           className="block w-full rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto"
    //         >
    //           Get Started
    //         </a>
    //         <a
    //           href="#"
    //           className="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-rose-600 shadow hover:text-rose-700 focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
    //         >
    //           Learn More
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default Banners;
