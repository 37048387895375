import React, { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Heading, Text } from "../../../components/UserComponents";
import CheckoutProduct from "../../../components/UserComponents/CheckoutProduct";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GST_IN_PERCENTAGE,
  SHIPPING_CHARGE,
  USER_URL,
} from "../../../constants";
import { ChevronDown, LoaderCircle, Truck } from "lucide-react";
import { getAddresses } from "../../../utils/api/address";
import { RadioGroup } from "../../../components/UserComponents/RadioGroup";
import { Radio } from "../../../components/UserComponents/Radio";
import axiosInstance from "../../../utils/api/axiosInstance";
import showToast from "../../../utils/showToast";
import { PayPalButtons } from "@paypal/react-paypal-js";

export default function CheckoutPage() {
  const [address, setAddress] = useState([]);
  const [visibleAddresses, setVisibleAddresses] = useState([]);
  const [showMoreOption, setShowMoreOption] = useState(false);
  const [orderPayload, setOrderPayload] = useState({
    addressId: null,
    paymentMethod: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const orderRef = useRef(null);

  // const [{ isPending }] = usePayPalScriptReducer();
  const { cart } = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const [subTotal, orderTotal] = useMemo(() => {
    const total = cart.reduce(
      (acc, curr) => acc + curr.price * curr.quantity,
      0
    );
    let amountToPay = total + Math.ceil((total * GST_IN_PERCENTAGE) / 100);
    amountToPay += SHIPPING_CHARGE;
    return [total, amountToPay];
  }, [cart]);

  useEffect(() => {
    if (!cart.length) navigate("/cart");
  }, [cart]);

  useEffect(() => {
    async function fetchAddress() {
      const addresses = await getAddresses();
      setAddress(addresses);
      setVisibleAddresses(addresses.slice(0, 2));
      setShowMoreOption(addresses.length > 2);
    }
    fetchAddress();
  }, []);

  const isEveryProductStockAvailable = useMemo(
    () => cart.every((item) => item.quantity < item.stock),
    [cart]
  );

  const handleShowMoreAddress = () => {
    setVisibleAddresses(address);
    setShowMoreOption(false);
  };
  const handleAddressSelection = (addressId) =>
    setOrderPayload((prev) => ({ ...prev, addressId }));

  const handlePaymentMethod = (paymentMethod) =>
    setOrderPayload((prev) => ({ ...prev, paymentMethod }));

  const handlePlaceOrder = async (isOnlinePayment = false, paymentMethod) => {
    try {
      if (!isEveryProductStockAvailable) {
        showToast("Please remove the out of stock item to proceed", "dark");
        return;
      }
      setIsSubmitting(true);
      const { data } = await axiosInstance.post(
        USER_URL + "/order",
        isOnlinePayment ? { ...orderPayload, paymentMethod } : orderPayload
      );

      const { payment, orderData } = data.data;
      console.log(payment, orderData);
      if (orderData.isCOD) {
        navigate(
          "/order-confirmation" + `/${orderData?._id}?isCod=true&success=true`
        );
        return;
      }
      if (orderData?.paymentMethod === "stripe") {
        window.location.assign(payment.url);
        return;
      }
      if (orderData?.paymentMethod === "paypal") {
        orderRef.current = orderData;
        return payment.sessionId;
      }
    } catch ({ response }) {
      showToast(response.data.message, "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  function onApprove() {
    const order = orderRef.current;
    navigate(`/order-confirmation/${order._id}?success=true`);
  }

  return (
    <>
      <Helmet>
        <title>
          Secure Checkout - Complete Your Purchase with Nexton eCommerce
        </title>
        <meta
          name="description"
          content="Ready to finalize your order? Proceed through our secure checkout process to confirm your purchase. Enjoy a seamless shopping experience with Nexton eCommerce, from cart to confirmation."
        />
      </Helmet>
      <div className="w-full bg-white-A700 lg:px-5">
        {/* header section */}

        <div className="flex justify-center pb-[72px] pt-10 md:pb-5 sm:py-5">
          {/* breadcrumb section */}
          <div className="container-xs flex flex-col gap-[25px] md:p-5 sm:gap-[26px]">
            <div className="flex flex-col items-start gap-[5px]">
              <div className="flex items-center text-sm mb-4">
                <Link to="/" className="text-gray-500">
                  Home
                </Link>
                <span className="mx-2 text-gray-300">&gt;</span>
                <span className="text-gray-900 font-medium">Checkout</span>
              </div>
              <Heading size="lg" as="h1">
                Checkout
              </Heading>
            </div>

            {/* checkout form section */}
            <div className="flex items-start gap-10 md:flex-col">
              <div className="flex w-full flex-col gap-10">
                <div className="flex flex-col items-start gap-6 lg:gap-3 rounded-[16px] border border-solid border-gray-200 pt-[25px] sm:pt-5">
                  <div className="px-2 flex justify-between w-full gap-3 md:ml-0">
                    <div className="flex items-center gap-3">
                      <Truck className="h-[24px] w-[24px]" />
                      <Text as="p" className="!font-medium">
                        SHIPPING ADDRESS
                      </Text>
                    </div>
                    <Button
                      color="gray_900"
                      shape="round"
                      size="sm"
                      className="px-2 font-medium text-white-A700"
                      onClick={() => navigate("/address?from=checkout")}
                    >
                      Add Address
                    </Button>
                  </div>
                  {/* Radio input for address selection */}
                  <div className="grid sm:grid-cols-1 grid-cols-2  gap-3 px-4 py-2 ">
                    {visibleAddresses.length ? (
                      <>
                        {visibleAddresses.map((address) => (
                          <AddressCard
                            {...address}
                            key={address._id}
                            handleAddressSelection={handleAddressSelection}
                          />
                        ))}
                      </>
                    ) : (
                      <p className="text-sm text-gray-600 text-center mx-auto">
                        Add new address by clicking the add address button
                      </p>
                    )}
                  </div>
                  {showMoreOption && (
                    <button
                      className="flex gap-1 items-center text-blue-500 font-medium p-2 pt-0"
                      onClick={handleShowMoreAddress}
                    >
                      <ChevronDown className="size-6" /> Show More address
                    </button>
                  )}
                </div>
              </div>
              <div className="flex w-full flex-col gap-6 lg:-mt-10">
                {/* order summary section */}
                <div className="flex flex-col gap-5">
                  <div className="flex">
                    <Heading size="md" as="h4">
                      Order summary
                    </Heading>
                  </div>
                  <div className="flex flex-col gap-px">
                    {cart.map((item) => (
                      <CheckoutProduct
                        {...item}
                        key={item._id}
                        isDisabled={true}
                      />
                    ))}
                  </div>
                </div>

                {/* order total section */}
                <div className="flex flex-col gap-6 border-t border-solid border-gray-200 pt-6 sm:pt-5">
                  <div className="flex flex-col gap-[7px]">
                    <div className="flex justify-between gap-5">
                      <div className="flex">
                        <Text as="p">Subtotal</Text>
                      </div>
                      <div className="flex">
                        <Text as="p">${subTotal}</Text>
                      </div>
                    </div>
                    <div className="flex justify-between gap-5">
                      <div className="flex">
                        <Text as="p">Shipping estimate</Text>
                      </div>
                      <div className="flex pt-px">
                        <Text as="p">${SHIPPING_CHARGE}</Text>
                      </div>
                    </div>
                    <div className="flex justify-between gap-5">
                      <Text as="p">Tax estimate</Text>
                      <div className="flex">
                        <Text as="p">{GST_IN_PERCENTAGE}%</Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap justify-between gap-5">
                    <Heading as="h6">Order total</Heading>
                    <Heading as="h6">${orderTotal}</Heading>
                  </div>
                  {}
                  <RadioGroup
                    onChange={handlePaymentMethod}
                    className="flex flex-col gap-2"
                  >
                    <Radio label="Cash on Delivery" value="cod" />
                    <Radio label="Pay Online" value="Pay online" />
                  </RadioGroup>
                  {/* shows only when payment method is pay online */}
                  {orderPayload.paymentMethod &&
                    orderPayload.paymentMethod !== "cod" && (
                      <>
                        {!orderPayload.addressId ? (
                          <p className="text-sm text-red-500 ">
                            Please select an address
                          </p>
                        ) : (
                          <div className="flex sm:flex-col  items-center gap-4 w-full">
                            <PayPalButtons
                              createOrder={() =>
                                handlePlaceOrder(true, "paypal")
                              }
                              onApprove={onApprove}
                              style={{
                                layout: "horizontal",
                                color: "white",
                                shape: "rect",
                                tagline: false,
                              }}
                              className="w-full max-w-xs h-10 disabled:opacity-50"
                              disabled={isSubmitting}
                            />
                            <button
                              disabled={isSubmitting}
                              onClick={() => handlePlaceOrder(true, "stripe")}
                              className="flex items-center justify-center bg-[#635BFF] text-white-A700 font-medium rounded-md px-4 py-2 hover:bg-[#4b44d7] transition-all duration-300 w-full max-w-xs mx-auto shadow-lg disabled:opacity-50"
                            >
                              <img
                                src="https://freelogopng.com/images/all_img/1685814539stripe-icon-png.png"
                                alt="Stripe Logo"
                                className="h-5 mr-2"
                              />
                              Pay with Stripe
                            </button>
                          </div>
                        )}
                      </>
                    )}
                </div>
                {/* confirm order button section */}
                {orderPayload && orderPayload.paymentMethod === "cod" && (
                  <Button
                    color="gray_900"
                    className="w-full rounded-[26px] py-3 font-medium sm:px-5 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={!orderPayload.addressId || isSubmitting}
                    onClick={() => handlePlaceOrder()}
                  >
                    Confirm order
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <OrderPlacedMessage show={confirm} /> */}
    </>
  );
}

const AddressCard = ({
  name,
  address,
  pin,
  phone,
  _id,
  handleAddressSelection,
}) => {
  return (
    <div className="relative flex items-start pt-2 gap-2">
      <div className="ml-3 flex items-center h-5">
        <input
          id={_id}
          name={"address_radio_selection"}
          type="radio"
          onChange={() => handleAddressSelection(_id)}
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
        />
      </div>
      <div
        className={`bg-white-A700  rounded-lg shadow-sm p-4 border  border-gray-200
space-y-2 sm:order-2 `}
      >
        <div className="flex items-start justify-between ">
          <p className="font-medium text-sm text-black p-1 px-2 bg-gray-200 rounded-md">
            {name}
          </p>
        </div>
        <p className="text-sm text-gray-600 ">{address}</p>
        <div className="flex flex-col gap-px">
          <p className="text-sm text-gray-600 inline-flex items-center gap-1 md:px-1">
            <span className="font-medium text-black">Pin:</span>
            {pin}
          </p>
          <p className="text-sm text-gray-600 inline-flex items-center gap-1 md:px-1">
            <span className="font-medium text-black">Phone:</span>
            {phone}
          </p>
        </div>
      </div>
    </div>
  );
};
